<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      Tags
    </b-card-title>
    <b-card-sub-title>
      Tagging system
    </b-card-sub-title>
    
    <b-form class="mt-2" @submit.prevent="updateTags">

      <b-row>
        <b-col v-for="(forms, name) in metrics" :key="name" sm="12" >
          <b-card no-body class="mb-0">
            <b-card-header @click="toggleCollapse(name)" class="d-flex justify-content-between align-items-center p-1">
              <h4 class="mb-0">{{ name }}</h4>
              <div>
                <feather-icon icon="CheckCircleIcon" v-if="isMetricFilled(name)" class="text-success" size="20" />
                <b-button size="sm" variant="outline-secondary" v-b-toggle="'collapse-' + name">
                  {{ isExpanded[name] ? 'Collapse' : 'Expand' }}
                </b-button>
              </div>
            </b-card-header>
            <b-collapse :id="'collapse-' + name" v-model="isExpanded[name]">
              <b-card-body>
                <b-row class="mt-2">
                  <b-col v-for="(options, form) in forms" :key="form" sm="12">
                    <validation-provider v-slot="validationContext" :ref="name + '-' + form" rules="required" :name="name + '-' + form">
                      <b-form-group :label="form" :label-for="name + '-' + form">
                        <v-select :id="name + '-' + form" v-model="selectedElement[name][form]" label="text"
                          :placeholder="`Select the ${form}`" :options="options" :disabled="options && options.length === 0"
                          :multiple="form === 'Answer'" :state="getValidationState(validationContext)" />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
      <b-button type="button" variant="primary" @click="updateTags" class="mt-2">
        <feather-icon v-if="!isUpdating" size="16" icon="SaveIcon" class="mr-50" />
        <b-spinner v-if="isUpdating" small />
        <span v-if="isUpdating"> Updating...</span>
        <span v-else>Update Tags</span>
      </b-button>
    </b-form>
  </b-card>



</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInvalidFeedback,
  VBTooltip,
  BButton,
  BSpinner,
  BCardHeader,
  BCardBody,
  VBToggle,
  BCollapse
} from "bootstrap-vue";
import programsService from "@/services/programsService";
import formsService from "@/services/formsService";
import vSelect from "vue-select";
import { reportSections } from "@/models";
import ActionCheckbox from "@/views/components/common/ActionCheckbox.vue";
import { VueGoodTable } from "vue-good-table";
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState,
  makeSuccessToast,
  makeErrorToast,
} from "@/libs/utils";


export default {
  components: {
    VueGoodTable,
    BForm,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInvalidFeedback,
    ActionCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    BCardHeader,
    BCardBody,
    VBToggle,
    BCollapse
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  props: {
    program: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    filteredMetrics() {
      const filtered = {};
      for (const key in this.metrics) {
        filtered[key] = Object.fromEntries(
          Object.entries(this.metrics[key]).filter(([_, options]) => options.length > 0)
        );
      }
      return filtered;
    }
  },
  data() {
    return {
      localProgram: { ...this.program },
      isLoading: false,
      isUpdating: false,
      forms: [],
      metrics: {},
      selectedElement: {},
      isExpanded: {},
      allExpanded: false,
    };
  },
  watch: {
    selectedElement: {
      async handler(newSelection) {
        for (const key in newSelection) {
          const formSelected = newSelection[key]["Form"];

          const questionSelected = newSelection[key]["Question"];
          if (formSelected) {
            try {
              const questions = [];
              formSelected.questions.map(q => {
                if(q.statements && q.statements.length > 0){
        
                  q.statements.map(s => questions.push({
                    ...s,
                    choices: q.choices,
                    text : (q.question_order + 1)  +' : '+ s.translations[0].question_text,
                    value : s.id
                  }))
                }else{
                  questions.push({
                    ...q,
                    text: q.translations[0].question_text,
                    value: q.id
                  })
                }
               })
    
              this.$set(this.metrics[key], "Question", questions);
            } catch (error) {
              console.error("Error fetching questions:", error);
            }
          }

          //If Question is selected, fetch the answers related to it
          if (questionSelected) {
            let choices = []
            try {

              if(questionSelected.choices.length > 0){
      
                choices = questionSelected.choices.map(a => ({
                ...a,
                text: a.translations[0].choice_text,
                value: a.id
              }))
              }else{
                const parentQuestion =  formSelected.questions.find(q =>  q.id === questionSelected.parent_id)
          
                choices = parentQuestion.choices.map(a => ({
                  ...a,
                  text: a.translations[0].choice_text,
                  value: a.id
                }))
              }
              
              this.$set(this.metrics[key], "Answer",choices);
            } catch (error) {
              console.error("Error fetching answers:", error);
            }
          }
        }
      },
      deep: true,
    }
  },
  methods: {
    toggleCollapse(name) {
      this.$set(this.isExpanded, name, !this.isExpanded[name]);
    },
    toggleAll() {
      this.allExpanded = !this.allExpanded;
      Object.keys(this.metrics).forEach(name => {
        this.$set(this.isExpanded, name, this.allExpanded);
      });
    },
    isMetricFilled(name) {
      return Object.values(this.selectedElement[name] || {}).some(value => {
        return Array.isArray(value) ? value.length > 0 : !!value;
      });
    },
    resetSelections() {
      Object.keys(this.selectedElement).forEach((key) => {
        this.$set(this.selectedElement[key], "Form", null);
        this.$set(this.selectedElement[key], "Question", null);
        this.$set(this.selectedElement[key], "Answer", null);
      });
    },
    updateTags() {
      this.isUpdating = true;
   
      const tags = Object.entries(this.selectedElement).map(([firstkey, value]) => {
        return Object.entries(value).map(([key, value]) => {
          const idsArray = []
          if (Array.isArray(value)) {
            value.map((item) => {
              idsArray.push(item.id)
            })
     
          } else if (value) {
            idsArray.push(value.id)
          }

          return {
              metric: firstkey,
              form: key,
              ids: idsArray
            };
        });
      }).flat();

      programsService.updateTags(this.program.id, tags)
        .then(() => {
          this.$toast(makeSuccessToast());
          this.isUpdating = false;
        })
        .catch((e) => {
          this.$toast(makeErrorToast());
          this.isUpdating = false;
          console.log(e)
        });
    }
  },
  async created() {
    try {
      this.isLoading = true;
      const result = await programsService.getTags(this.program.id);

      const grouped = result.data.reduce((acc, item) => {
        if (!acc[item.parent]) {
          acc[item.parent] = [];
        }
        acc[item.parent].push(item);
        return acc;
      }, {});

      const response = await formsService.getProgramForms(this.program.id);

      Object.keys(grouped).forEach(key => {
        // Initialize selectedElement properties
        if (!this.selectedElement[key]) {
          this.$set(this.selectedElement, key, {});
        }
        this.$set(this.selectedElement[key], "Form", null);
        this.$set(this.selectedElement[key], "Question", null);
        this.$set(this.selectedElement[key], "Answer", null);

        this.$set(this.metrics, key, {
          Form: [],
          Question: [],
          Answer: []
        });
        this.metrics[key]['Form'] = response.data.map(form => ({
          ...form,
          text: form.application_set_id
            ? `Application - ${form.roles[0]?.name}`
            : form.translations[0].title,
          value: form.id

        }));


        // Auto-select Form if a taggable form exists
        const selectedFormTag = grouped[key].find(tag =>
          tag.taggables.some(t => t.taggable_type.includes("Form"))
        );

        if (selectedFormTag) {
          this.selectedElement[key]["Form"] = this.metrics[key]['Form'].find(
            f => f.id === selectedFormTag.taggables[0].taggable_id
          );
        }

        // Populate "Question" dropdown if a form is selected
      if (this.selectedElement[key]["Form"]) {
        const questions = [];
              this.selectedElement[key]["Form"].questions.map(q => {
       
                if(q.statements.length > 0){
                  q.statements.map(s => { 
                    questions.push({
                    ...s,
                    choices: q.choices,
                    text : s.translations[0].question_text,
                    value : s.id
                  })})
                }else{
                  questions.push({
                    ...q,
                    text: q.translations[0].question_text,
                    value: q.id
                  })
                }
               })
   
              this.$set(this.metrics[key], "Question", questions);

              this.metrics[key]["Question"] = questions;
        
       

        // Auto-select Question if it exists
        const selectedQuestionTag = grouped[key].find(tag => {
          return tag.name === 'Question'
        });
  
        if (selectedQuestionTag) {
          this.selectedElement[key]["Question"] = questions.find(
            (q) => { 
              return q.id === selectedQuestionTag.taggables[0]?.taggable_id}
          );
        }
      }

      // Populate "Answer" dropdown if a question is selected
      if (this.selectedElement[key]["Question"]) {
         
        let choices = [];
        if(this.selectedElement[key]["Question"].choices.length > 0){
          choices = this.selectedElement[key]["Question"].choices.map(a => ({
          ...a,
          text: a.translations[0].choice_text,
          value: a.id
        }))
        }else{
          const parentQuestion =  this.selectedElement[key]["Form"].questions.find(q =>  q.id === this.selectedElement[key]["Question"].parent_id)
          choices = parentQuestion.choices.map(a => ({
            ...a,
            text: a.translations[0].choice_text,
            value: a.id
          }))
        }
        this.metrics[key]["Answer"] = choices;


        // Auto-select Answer if it exists (multi-select)
        const selectedAnswerTags = grouped[key].filter(tag =>
          tag.taggables.some(t => t.taggable_type.includes("QuestionChoice"))
        );

        this.selectedElement[key]["Answer"] = selectedAnswerTags.flatMap(tag =>
            tag.taggables.map(taggable =>
                this.metrics[key]["Answer"].find(a => a.id === taggable.taggable_id)
            )
        ).filter(Boolean);

      }


      });
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.$toast(makeErrorToast());
    }
  },
  setup() {
    return {
      getValidationState,
      reportSections
    };
  }
};
</script>

<style lang="scss">
.vgt-responsive {
  min-height: unset;
}
</style>
